<template>

<b-card>
    <b-row>
      <h4 class="   font-weight-bold mx-1 mb-2">
        Module List
      </h4>
      <b-col md="12">
       

        <!-- draggable -->
        <draggable
          :list="permissionsData"
          tag="ul"
          group="people"
          class="list-group list-group-flush cursor-move"
        >
          <b-list-group-item
            v-for="(listItem, index) in permissionsData"
            :key="index"
            tag="li"
          >
            <div class="d-flex">
              <b-avatar :text="listItem.title.slice(0,3)" />
              <div class="ml-25">
                <b-card-text class="mb-0 font-weight-bold">
                  {{ listItem.title }}
                </b-card-text>
                <small>{{ listItem.title }}</small>
              </div>
            </div>
            <div>
              <b-list-group>
    <b-list-group-item 
    class="d-flex justify-content-between align-items-center"
    v-for="(featureItem, i) in listItem.ModuleFeatures" :key="i"
    >
      <span>{{featureItem.title}}</span>
      <b-form-checkbox
          
          :checked="resolveFeatureStatus(featureItem)"
          name="check-button"
          @change="toogleFeature(featureItem,listItem)"
          switch
          inline
        >
          <span>{{resolveFeatureStatus(featureItem)?'Active&nbsp;&nbsp;':'Disabled'}}</span>  {{featureItem.monthlyPrice}}
        </b-form-checkbox>
    </b-list-group-item>

    
 
  </b-list-group>
              </div>
          </b-list-group-item>
        </draggable>
      </b-col>
     
      
      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
            @click.prevent="sendInformation"
        >
          Save changes
        </b-button>
      
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
  </template>
  
  <script>
  import {
   BAvatar, BButton, BRow, BCol, BCard,BCardHeader, BCardTitle, BCardText, BFormCheckbox,BBadge, BListGroup, BListGroupItem ,BTable
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import axios from "@axios";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import vSelect from 'vue-select'
import store from '@/store' 
import draggable from 'vuedraggable'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
  export default {
    components: {
      BButton,BAvatar,draggable,
      BTable,
      BRow,
      BCol,
      BCard,BCardHeader, BCardTitle,BCardText,
      BBadge,
      BFormCheckbox, BListGroup, BListGroupItem 
    },
    directives: {
      Ripple,
    },
    props: {
      accountPreferences: {
        type: Object,
        default: () => {
         
        },
      },
    },
   
     created() {
       
       this.fetchCategories();
      this.localSettings = this.accountPreferences.data==null||JSON.parse(JSON.stringify(this.accountPreferences.data)).accountsettings ==null?
      {  useCustomLogin: false }: JSON.parse(JSON.parse(JSON.stringify(this.accountPreferences.data)).accountsettings );//his.generalData;
      console.log(this.localSettings);
      console.log("account preferences");
    
  
  
    },
    data() {
     
  
      return {
        modules:null,
        isLoading: false,
        isUpdated: false,
        clientRoles:null,
        permissionsData:null,
        localSettings: {
             useCustomLogin: false ,
             anotherSetting: false,
      },
      }
    },
    setup() {
      const clientRoles = ref(null)
      const toast = useToast()
        clientRoles.value= JSON.parse(localStorage.getItem("projectSettings"));
     console.log("initializing",clientRoles);
      
     
    // UnRegister on leave
  const  resolveFeatureStatus   = status => {
    console.log(status);
    console.log(clientRoles.value);

    let obj = clientRoles.value.find(o => o.id === status.id);
    console.log(obj);
    if (obj ===undefined) return false; 
    
    console.log("returning true");
    return true;
  }
  const  toogleFeature   = (status,modul) => {
    console.log(status);
    console.log(clientRoles.value);

    let obj = clientRoles.value.find(o => o.id === status.id);
    console.log(obj);

    
    var data={
          moduleFeatureId:status.id,
          moduleId:modul.id,
        };
  
      
        axios({
          method: "post",
          url: `/admin/updateclientmodule`,
          data:data ,
        })
          .then((r) => {

            if (obj ===undefined) {
      clientRoles.value.push(status);
      console.log("pushing");
    }else{
      clientRoles.value.splice(clientRoles.value.indexOf(obj), 1);
      console.log("removing");
    }; 

            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: "Your changes have been saved! and It will effect on next login",
            
              },
            });
            
          })
          .catch((r) => {
             toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertOctagonIcon",
                variant: "warning",
                text: "Something went wrong!",
              },
            });
            
          })

    
    
     
    return true;
  }

    

    return {
      resolveFeatureStatus,
      toogleFeature
    }
  },
    methods: {
      
      getRoleSettings()
      { 
        
       this.clientRoles= JSON.parse(localStorage.getItem("projectSettings"));
  console.log(this.clientRoles);

      },

      fetchCategories( ) {
      console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/modules' )
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
          console.log(responseData.data);
             this.permissionsData=responseData.data;
          

         

            }
            )
          .catch(error => reject(error))
      })
    },
       sendInformation(featureId,moduleId) {
        
        var data={
          moduleFeatureId:featureId,
          moduleId:moduleId,
        };
  
        this.isLoading = true;
        axios({
          method: "post",
          url: `/admin/updateclientmodule`,
          data:data ,
        })
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: "Your changes have been saved! and It will effect on next login",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          })
          .catch((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertOctagonIcon",
                variant: "warning",
                text: "Something went wrong!"+ r,
              },
            });
            this.isLoading = false;
          });
        return;
      },
      resetForm() {
        this.localSettings = JSON.parse(JSON.stringify(this.accountPreferences))
      },
    },
  }
  </script>
  