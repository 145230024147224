<template>
  <b-card>
    <!-- form -->
    <b-row>
      <b-col cols="10">
        <h2 class="text-primary">{{ title }}</h2>
      </b-col>

      <b-col cols="2">
        <b-badge variant="warning" class="badge-glow" v-if="isUpdated">
          Not Saved
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12">
        <mail-template-selector
          v-if="!!categories"
          :initialCategories="categories"
          @selectionChanged="selectionChanged"
        ></mail-template-selector>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-form-group label=" Mail Title" label-for="templatetitle">
          <b-form-input
            v-model="selectedTemplate.templatetitle"
            name="mailtitle"
            placeholder="Mail Title "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-form>
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group label="" label-for="bio-area">
            <quill-editor
              v-model="selectedTemplate.template_content"
              height="300"
              :options="snowOption"
            
              @change="onEditorChange($event)"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            />
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <!-- phone -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :disabled="isLoading ? true : false"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            :disabled="isLoading ? true : false"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { quillEditor } from "vue-quill-editor";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MailTemplateSelector from "./component/TemplateSelector.vue";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { integer } from "@/@core/utils/validations/validations";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    quillEditor,
    BBadge,
    MailTemplateSelector,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => "",
    },

    informationData: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  data() {
    return {
      countryOption: ["USA", "India", "Canada"],
      snowOption: {
        theme: "snow",
      },
      isLoading: false,
      isUpdated: false,
      selectedTemplate: {
        templatetitle: "",
        template_content: "",
      },
      selectedBaseMail: null,
      canupdate: false,
      selectedContent: null,
      categories: null,
      localOptions: JSON.parse(JSON.stringify(this.informationData ?? "")),

      clevePhone: {
        phone: true,
        phoneRegionCode: "US",
      },
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    selectionChanged(value) {
      console.log("template option Changed");
      console.log(value);
      this.selectedBaseMail = value;
      const contents = value.ClientMailTemplates;
      if (contents.length > 0) {
        this.selectedTemplate = contents[0];
        this.canupdate = true;
      } else {
        this.selectedTemplate = {
          templatetitle: "",
          template_content: "",
        };
        this.canupdate = false;
      }
    },

    fetchCategories() {
      console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
        axios
          .get("/admin/f/getmailtemplates")
          .then((response) => {
            var data = response.data;
            var responseData = JSON.parse(JSON.stringify(data));
            console.log(responseData.data);
            this.categories = responseData.data;
            this.selected = this.categories[0];
          })
          .catch((error) => reject(error));
      });
    },

    sendInformation() {
      console.log(
        "update course  is launching with id : " + this.selectedBaseMail.id
      );

      



      var sentData = {
        base: this.selectedBaseMail.id,
        content: this.selectedTemplate,
      };

      console.log("sentData");
      console.log(sentData);

      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/f/clientmailtemplate`,
        data: sentData,
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
    
    },

    resetForm() {
      this.localOptions = JSON.parse(
        JSON.stringify(this.informationData ?? "")
      );
    },
    onEditorBlur(quill) {
      //    console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      //   console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      //     console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      //     console.log('editor change!', quill, html, text)
      this.isUpdated = true;
      
      if (this.selectedTemplate != null) {
        this.selectedTemplate.template_content = html;
      }
    },
  },
  computed: {
    editor() {
      //   return this.$refs.myQuillEditor.quill
    },
  },
  mounted() {
    var data = JSON.parse(JSON.stringify(this.informationData ?? ""));

    //   console.log(data);

    //   console.log('this is current quill instance object', this.editor)
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

@import "@core/scss/vue/libs/quill.scss";

.ql-editor {
  min-height: 400px;
}
</style>

